import SectionTitle from "../../../components/section-title";
import LaserEyeSurgeryItem from "../../../components/laser-eye-surgery";
import HomeData from "../../../data/home.json";
import ServiceData from "../../../data/service.json";
import QualifiesData from "../../../data/who-qualifies.json";
import CostsData from "../../../data/costs.json";
import PropTypes from "prop-types";

const EyeSurgeryContainer = ({ surgeryClassOption, page }) => {
    return (
        <div className="surgery-area surgery-default-area">
            <div className="container">
                {page === 'Service' && <div className="row justify-content-center">
                    <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                        <SectionTitle
                            classOption="text-center"
                            header="<span>What are the types of laser eye surgery?</span>"
                            description="Laser vision correction works by correcting the focus of the cornea. The different types of laser eye surgery are: LASIK, LASEK, ReLEx SMILE. We are the only UK group to offer all these options, based on what is ideal for your unique vision correction needs."

                        />
                    </div>
                </div>}
                {page === 'Costs' && <div className="row justify-content-center">
                    <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                        <SectionTitle
                            classOption="text-center"
                            header="<span>What’s included in the cost of your laser eye surgery package?</span>"

                        />
                    </div>
                </div>}
                <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                >
                    {page === '' && HomeData[8].surgery &&
                        HomeData[8].surgery.map((single, key) => {
                            return (
                                <div
                                    key={key}
                                    className="col-sm-6 col-md-6 col-lg-4"
                                >
                                    <LaserEyeSurgeryItem data={single} />
                                </div>
                            );
                        })}
                    {page === 'Service' && ServiceData[8].surgery &&
                        ServiceData[8].surgery.map((single, key) => {
                            return (
                                <div
                                    key={key}
                                    className="col-sm-6 col-md-6 col-lg-4"
                                >
                                    <LaserEyeSurgeryItem data={single} />
                                </div>
                            );
                        })}
                    {page === 'Costs' && CostsData[2].surgery &&
                        CostsData[2].surgery.map((single, key) => {
                            return (
                                <div
                                    key={key}
                                    className="col-sm-6 col-md-6 col-lg-3"
                                >
                                    <LaserEyeSurgeryItem data={single} />
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

EyeSurgeryContainer.propTypes = {
    surgeryClassOption: PropTypes.string,
    page: PropTypes.string,
};
EyeSurgeryContainer.defaultProps = {
    surgeryClassOption: "row row-gutter-0 eye-surgery-items-style1",
};

export default EyeSurgeryContainer;
