import React from "react";
import { NavLink } from "react-router-dom";
import 'font-awesome/css/font-awesome.min.css';
const MainMenu = () => {
    return (
        <nav>
            <ul className="main-menu">
                <li className="active">
                    <NavLink className="main-menu-link" exact to="/">
                        Laser Eye Surgery <i className="fa fa-caret-down"></i>
                    </NavLink>
                    <ul className="sub-menu">
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/service"}
                            >
                                What is Laser Eye Surgery?
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/lasereyesurgery/who-qualifies"}
                            >
                                Am I Suitable?
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/lasereyesurgery/costs"}
                            >
                                Laser Eye Surgery Costs
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/lasereyesurgery/lasik"}
                            >
                                LASIK
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/lasereyesurgery/lasek"}
                            >
                                LASEK
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/lasereyesurgery/relex-smile"}
                            >
                                ReLEx SMILE
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/lasereyesurgery/recovery-after-care"}
                            >
                                Recovery &amp; After Care
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/lasereyesurgery/risks"}
                            >
                                Risks
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/lasereyesurgery/laser-eye-faqs"
                                }
                            >
                                Laser Eye FAQs
                            </NavLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <NavLink
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/lensreplacement/lens-replacement"}
                    >
                        Lens Replacement <i className="fa fa-caret-down"></i>
                    </NavLink>
                    <ul className="sub-menu">
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/lensreplacement/lens-replacement"}
                            >
                                what is Lens Replacement Surgery?
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/lensreplacement/am-i-suitable"
                                }
                            >
                                Am i Suitable?
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/lensreplacement/lens-replacement-cost"
                                }
                            >
                                Lens Replacement Costs
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/lensreplacement/clarivu"
                                }
                            >
                                Clarivu
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/lensreplacement/replacement-risks"
                                }
                            >
                                Risks
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/lensreplacement/lens-replacement-faqs"
                                }
                            >
                                Lens Replacement FAQs
                            </NavLink>
                        </li>
                    </ul>
                </li>

                <li>
                    <NavLink
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/cataracts/cataractsurgery"}
                    >
                        Cataracts <i className="fa fa-caret-down"></i>
                    </NavLink>
                    <ul className="sub-menu">
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/cataracts/cataractsurgery"}
                            >
                                What is Cataracts Surgery?
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/cataracts/cataractsurgerycosts"}
                            >
                                Cataracts Surgery Costs
                            </NavLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <NavLink
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/eyeconditions/medical-treatments"}
                    >
                        Eye Conditions  <i className="fa fa-caret-down"></i>
                    </NavLink>
                    <ul className="sub-menu">
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/eyeconditions/medical-treatments"}
                            >
                                Medical Treatments
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/eyeconditions/glaucoma"
                                }
                            >
                                Glaucoma
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/eyeconditions/eye-floaters"
                                }
                            >
                                Eye Floaters
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/eyeconditions/hyperopia"
                                }
                            >
                                Hyperopia
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/eyeconditions/cataracts"
                                }
                            >
                                Cataracts
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/eyeconditions/retinal-detachment"
                                }
                            >
                                Retinal Detachment
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/eyeconditions/presbyopia"
                                }
                            >
                                Presbyopia
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/eyeconditions/astigmatism"
                                }
                            >
                                Astigmatism
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/eyeconditions/droopyeyelid"
                                }
                            >
                                Droopy Eyelid
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/eyeconditions/macularhole"
                                }
                            >
                                Macular Hole
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/eyeconditions/maculardegeneration"
                                }
                            >
                                Macular Degeneration
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/eyeconditions/myopia"
                                }
                            >
                                Myopia
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/eyeconditions/diabetic"
                                }
                            >
                                Diabetic Related Eye Conditions
                            </NavLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <NavLink
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/blog"}
                    >
                        Blog <i className="fa fa-caret-down"></i>
                    </NavLink>
                    <ul className="sub-menu">
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/blog"}
                            >
                                Blog list
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={process.env.PUBLIC_URL + "/blog-details/1"}
                            >
                                Blog Details
                            </NavLink>
                        </li>
                    </ul>
                </li>

                <li>
                    <NavLink
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/about"}
                    >
                        About <i className="fa fa-caret-down"></i>
                    </NavLink>

                    <ul className="sub-menu">
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/service-details/1"
                                }
                            >
                                Our Consultants
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/service-details/1"
                                }
                            >
                                Why choose us
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/service-details/1"
                                }
                            >
                                Our facilities
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/service-details/1"
                                }
                            >
                                Our technology
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/service-details/1"
                                }
                            >
                                Fight for Sight
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                className="sub-menu-link"
                                to={
                                    process.env.PUBLIC_URL +
                                    "/service-details/1"
                                }
                            >
                                Patient reviews
                            </NavLink>
                        </li>
                    </ul>
                </li>
                <li>
                    <NavLink
                        className="main-menu-link"
                        to={process.env.PUBLIC_URL + "/contact"}
                    >
                        Contact
                    </NavLink>
                </li>
            </ul>
        </nav>
    );
};

export default MainMenu;
