import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import HomePage from "./pages/index";
import "./assets/css/bootstrap.min.css";
import "./assets/scss/style.scss";
import "./assets/css/icofont.css";
import "swiper/components/navigation/navigation.scss";
import "swiper/swiper.scss";
import "./assets/css/animate.css";
import "lightgallery.js/dist/css/lightgallery.css";
import "swiper/components/pagination/pagination.scss";
import AboutPage from "./pages/about";
import ServicePage from "./pages/service";
// import ServiceDetails from "./templates/service-details";
import BlogPage from "./pages/blog";
import BlogDetailsPage from "./templates/blog-details";
import BlogCategory from "./templates/blog-category";
import BlogTag from "./templates/blog-tag";
import BlogDate from "./templates/blog-date";
import BlogAuthor from "./templates/blog-author";
import ContactPage from "./pages/contact";
import WhoQualifiesPage from "./pages/lasereyesurgery/who-qualifies";
import LasikPage from "./pages/lasereyesurgery/lasik";
import LasekPage from "./pages/lasereyesurgery/lasek";
import CostsPage from "./pages/lasereyesurgery/costs";
import RelexSmilePage from "./pages/lasereyesurgery/relex-smile";
import RecoveryAfterCarePage from "./pages/lasereyesurgery/recovery-after-care";
import RisksPage from "./pages/lasereyesurgery/risks";
import FAQPage from "./pages/lasereyesurgery/laser-eye-faqs";
import LensReplacementPage from "./pages/lensreplacement/lens-replacement";
import LensReplacementCostPage from "./pages/lensreplacement/lens-replacement-cost";
import LensReplacementFAQPage from "./pages/lensreplacement/lens-replacement-faqs";
import LensReplacementSuitablePage from "./pages/lensreplacement/clarivu";
import LensReplacementClarivuPage from "./pages/lensreplacement/am-i-suitable";
import LensReplacementRisksPage from "./pages/lensreplacement/replacement-risks";
import CataractSurgeryPage from "./pages/cataracts/cataractsurgery";
import CataractSurgeryCostsPage from "./pages/cataracts/cataractsurgerycosts";
import MedicalTreatmentsPage from "./pages/eyeconditions/medical-treatments";
import AstigmatismPage from "./pages/eyeconditions/astigmatism";
import GlaucomaPage from "./pages/eyeconditions/glaucoma";
import EyeFloatersPage from "./pages/eyeconditions/eye-floaters";
import HyperopiaPage from "./pages/eyeconditions/hyperopia";
import CataractsPage from "./pages/eyeconditions/cataracts";
import RetinalDetachmentPage from "./pages/eyeconditions/retinal-detachment";
import PresbiopiaPage from "./pages/eyeconditions/presbyopia";
import DroopyEyelidPage from "./pages/eyeconditions/droopyeyelid";
import MacularHolePage from "./pages/eyeconditions/macularhole";
import MacularDegenerationPage from "./pages/eyeconditions/maculardegeneration";
import MyopiaPage from "./pages/eyeconditions/myopia";
import DiabeticPage from "./pages/eyeconditions/diabetic";
import NavScrollTop from "./components/nav-scroll-top";

const App = () => {
    useEffect(() => {
        AOS.init({
            offset: 80,
            duration: 1000,
            once: true,
            easing: "ease",
        });
        AOS.refresh();
    }, []);
    return (
        // <Router>
        <Router basename={process.env.PUBLIC_URL}>
            <NavScrollTop>
                <Switch>
                    <Route
                        path={`${process.env.PUBLIC_URL + "/"}`}
                        // path={"/"}
                        exact
                        component={HomePage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/about"}`}
                        component={AboutPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/service"}`}
                        component={ServicePage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/lasereyesurgery/who-qualifies"}`}
                        component={WhoQualifiesPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/lasereyesurgery/lasik"}`}
                        component={LasikPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/lasereyesurgery/costs"}`}
                        component={CostsPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/lasereyesurgery/lasek"}`}
                        component={LasekPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/lasereyesurgery/relex-smile"}`}
                        component={RelexSmilePage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/lasereyesurgery/recovery-after-care"}`}
                        component={RecoveryAfterCarePage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/lasereyesurgery/risks"}`}
                        component={RisksPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/lasereyesurgery/laser-eye-faqs"}`}
                        component={FAQPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/lensreplacement/lens-replacement"
                            }`}
                        component={LensReplacementPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/lensreplacement/lens-replacement-cost"
                            }`}
                        component={LensReplacementCostPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/lensreplacement/clarivu"
                            }`}
                        component={LensReplacementClarivuPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/lensreplacement/lens-replacement-faqs"
                            }`}
                        component={LensReplacementFAQPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/lensreplacement/replacement-risks"
                            }`}
                        component={LensReplacementRisksPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/lensreplacement/am-i-suitable"
                            }`}
                        component={LensReplacementSuitablePage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/cataracts/cataractsurgery"
                            }`}
                        component={CataractSurgeryPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/cataracts/cataractsurgerycosts"
                            }`}
                        component={CataractSurgeryCostsPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/eyeconditions/medical-treatments"
                            }`}
                        component={MedicalTreatmentsPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/eyeconditions/astigmatism"
                            }`}
                        component={AstigmatismPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/eyeconditions/glaucoma"
                            }`}
                        component={GlaucomaPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/eyeconditions/eye-floaters"
                            }`}
                        component={EyeFloatersPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/eyeconditions/hyperopia"
                            }`}
                        component={HyperopiaPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/eyeconditions/cataracts"
                            }`}
                        component={CataractsPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/eyeconditions/retinal-detachment"
                            }`}
                        component={RetinalDetachmentPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/eyeconditions/presbyopia"
                            }`}
                        component={PresbiopiaPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/eyeconditions/droopyeyelid"
                            }`}
                        component={DroopyEyelidPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/eyeconditions/macularhole"
                            }`}
                        component={MacularHolePage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/eyeconditions/maculardegeneration"
                            }`}
                        component={MacularDegenerationPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/eyeconditions/myopia"
                            }`}
                        component={MyopiaPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/eyeconditions/diabetic"
                            }`}
                        component={DiabeticPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/blog"}`}
                        component={BlogPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/category/:slug"}`}
                        component={BlogCategory}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/tag/:slug"}`}
                        component={BlogTag}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/date/:date"}`}
                        component={BlogDate}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/author/:author"}`}
                        component={BlogAuthor}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/blog-details/:id"}`}
                        component={BlogDetailsPage}
                    />
                    <Route
                        path={`${process.env.PUBLIC_URL + "/contact"}`}
                        component={ContactPage}
                    />
                </Switch>
            </NavScrollTop>
        </Router>
    );
};

export default App;
