import SectionTitle from "../../../components/section-title";
import ServiceData from "../../../data/service.json";
import PropTypes from "prop-types";

const RightContentContainer = ({ page, i }) => {
    return (
        <div className="surgery-area surgery-default-area">
            <div className="container">
                {page === ServiceData[i].rightContent[0].page && <div className="row align-items-center">
                    <div className="col-12 col-md-6">

                        <div className="thumb">
                            <img
                                src={process.env.PUBLIC_URL + ServiceData[i].rightContent[0].image}
                                alt="hope-HasTech"
                            />
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <SectionTitle
                            classOption="text-left"
                            title={ServiceData[i].rightContent[0].header}
                            content={ServiceData[i].rightContent[0].description}

                        />
                    </div>
                </div>}

            </div>
        </div>
    );
};

RightContentContainer.propTypes = {
    page: PropTypes.string,
    i: PropTypes.string,
};

export default RightContentContainer;
