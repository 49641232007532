import SectionTitle from "../../../components/section-title";
import HomeData from "../../../data/home.json";
import ServiceData from "../../../data/service.json";
import QualifiesData from "../../../data/who-qualifies.json";
import CostsData from "../../../data/costs.json";
import LasikData from "../../../data/lasik.json";
import LasekData from "../../../data/lasek.json";
import RelexSmileData from "../../../data/relex-smile.json";
import RecoveryData from "../../../data/recovery-after-care.json";
import RisksData from "../../../data/risks.json";
import LensReplaceData from "../../../data/lens-replacement.json";
import SuitableData from "../../../data/am-i-suitable.json";
import ClarivuData from "../../../data/clarivu.json";
import CataractsData from "../../../data/cataracts.json";
import CataractSurgeryCostsData from "../../../data/cataractsurgerycosts.json";
import MedicalTreatmentsData from "../../../data/medical-treatments.json";
import ReplaceRisksData from "../../../data/replacement-risks.json";
import ReplacementCostData from "../../../data/lens-replacement-cost.json";
import GlaucomaData from "../../../data/glaucoma.json";
import FloatersData from "../../../data/eyefloaters.json";
import HyperopiaData from "../../../data/hyperopia.json";
import PresbiopiaData from "../../../data/presbyopia.json";
import AstigmatismData from "../../../data/astigmatism.json";
import DroopyEyelidData from "../../../data/droopyeyelid.json";
import MacularHoleData from "../../../data/macularhole.json";
import MacularDegenerationData from "../../../data/maculardegeneration.json";
import RetinalDetachmentData from "../../../data/retinal-detachment.json";
import MyopiaData from "../../../data/myopia.json";
import DiabeticData from "../../../data/diabetic.json";
import PropTypes from "prop-types";

const CenterContentContainer = ({ surgeryClassOption, page, i }) => {
    return (
        <div className="surgery-area surgery-default-area">
            <div className="container">
                {page === 'Home' && <div className="row justify-content-center">
                    <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                        <SectionTitle
                            classOption="text-left"
                            title={HomeData[i].centerContent[0].header}
                            content={HomeData[i].centerContent[0].description}

                        />
                    </div>
                </div>}
                {page === 'Service' && i === '11' &&
                    <div
                        className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                        data-aos="fade-up"
                        data-aos-duration="1100"
                    ><div className="row justify-content-center">
                            <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                                <SectionTitle
                                    classOption="text-left"
                                    title={ServiceData[i].centerContent[0].header}
                                    content={ServiceData[i].centerContent[0].description}
                                    header1={ServiceData[i].centerContent[0].header1}
                                    content1={ServiceData[i].centerContent[0].content1}
                                    header2={ServiceData[i].centerContent[0].header2}
                                    content2={ServiceData[i].centerContent[0].content2}
                                    header3={ServiceData[i].centerContent[0].header3}
                                    content3={ServiceData[i].centerContent[0].content3}

                                />
                            </div>
                        </div>
                    </div>}
                {page === 'Service' && i === '12' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={ServiceData[i].centerContent[0].header}
                                content={ServiceData[i].centerContent[0].content}
                                content1={ServiceData[i].centerContent[0].content1}
                                content2={ServiceData[i].centerContent[0].content2}
                                content3={ServiceData[i].centerContent[0].content3}
                                content4={ServiceData[i].centerContent[0].content4}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Service' && i === '14' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={ServiceData[i].centerContent[0].header}
                                content={ServiceData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Service' && i === '15' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={ServiceData[i].centerContent[0].header}
                                content={ServiceData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Service' && i === '16' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={ServiceData[i].centerContent[0].header}
                                content={ServiceData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Service' && i === '17' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={ServiceData[i].centerContent[0].header}
                                content={ServiceData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Qualifies' && i === '0' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                content={QualifiesData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Qualifies' && i === '2' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={QualifiesData[i].centerContent[0].header}
                                content={QualifiesData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Qualifies' && i === '3' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={QualifiesData[i].centerContent[0].header}
                                content={QualifiesData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Qualifies' && i === '4' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={QualifiesData[i].centerContent[0].header}
                                content={QualifiesData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Qualifies' && i === '6' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={QualifiesData[i].centerContent[0].header}
                                content={QualifiesData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Costs' && i === '0' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={CostsData[i].centerContent[0].header}
                                content={CostsData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Costs' && i === '1' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={CostsData[i].centerContent[0].header}
                                content={CostsData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Lasik' && i === '0' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={LasikData[i].centerContent[0].header}
                                content={LasikData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Lasik' && i === '2' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={LasikData[i].centerContent[0].header}
                                content={LasikData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Lasik' && i === '3' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={LasikData[i].centerContent[0].header}
                                content={LasikData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Lasik' && i === '4' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={LasikData[i].centerContent[0].header}
                                content={LasikData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Lasik' && i === '5' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={LasikData[i].centerContent[0].header}
                                content={LasikData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Lasik' && i === '7' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={LasikData[i].centerContent[0].header}
                                content={LasikData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Lasik' && i === '8' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={LasikData[i].centerContent[0].header}
                                content={LasikData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Lasek' && i === '0' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={LasekData[i].centerContent[0].header}
                                content={LasekData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Lasek' && i === '2' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={LasekData[i].centerContent[0].header}
                                content={LasekData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Lasek' && i === '3' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={LasekData[i].centerContent[0].header}
                                content={LasekData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Lasek' && i === '4' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={LasekData[i].centerContent[0].header}
                                content={LasekData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Lasek' && i === '5' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={LasekData[i].centerContent[0].header}
                                content={LasekData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Lasek' && i === '6' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={LasekData[i].centerContent[0].header}
                                content={LasekData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'RelexSmile' && i === '0' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={RelexSmileData[i].centerContent[0].header}
                                content={RelexSmileData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'RelexSmile' && i === '1' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={RelexSmileData[i].centerContent[0].header}
                                content={RelexSmileData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'RelexSmile' && i === '4' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={RelexSmileData[i].centerContent[0].header}
                                content={RelexSmileData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'RelexSmile' && i === '5' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={RelexSmileData[i].centerContent[0].header}
                                content={RelexSmileData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Recovery' && i === '0' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={RecoveryData[i].centerContent[0].header}
                                content={RecoveryData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Recovery' && i === '1' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={RecoveryData[i].centerContent[0].header}
                                content={RecoveryData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Recovery' && i === '4' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={RecoveryData[i].centerContent[0].header}
                                content={RecoveryData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Recovery' && i === '5' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={RecoveryData[i].centerContent[0].header}
                                content={RecoveryData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Risks' && i === '0' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                content={RisksData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Risks' && i === '1' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={RisksData[i].centerContent[0].header}
                                content={RisksData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Risks' && i === '2' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={RisksData[i].centerContent[0].header}
                                content={RisksData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Risks' && i === '3' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={RisksData[i].centerContent[0].header}
                                content={RisksData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Risks' && i === '4' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={RisksData[i].centerContent[0].header}
                                content={RisksData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'LensReplace' && i === '0' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={LensReplaceData[i].centerContent[0].header}
                                content={LensReplaceData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'LensReplace' && i === '1' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={LensReplaceData[i].centerContent[0].header}
                                content={LensReplaceData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'LensReplace' && i === '2' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={LensReplaceData[i].centerContent[0].header}
                                content={LensReplaceData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'LensReplace' && i === '3' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={LensReplaceData[i].centerContent[0].header}
                                content={LensReplaceData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'LensReplace' && i === '5' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={LensReplaceData[i].centerContent[0].header}
                                content={LensReplaceData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'LensReplace' && i === '6' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={LensReplaceData[i].centerContent[0].header}
                                content={LensReplaceData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'LensReplace' && i === '7' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={LensReplaceData[i].centerContent[0].header}
                                content={LensReplaceData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'LensReplace' && i === '8' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={LensReplaceData[i].centerContent[0].header}
                                content={LensReplaceData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Suitable' && i === '0' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                content={SuitableData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Suitable' && i === '1' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={SuitableData[i].centerContent[0].header}
                                content={SuitableData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Suitable' && i === '2' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={SuitableData[i].centerContent[0].header}
                                content={SuitableData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'ReplacementCost' && i === '0' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                content={ReplacementCostData[i].centerContent[0].content}
                                title={ReplacementCostData[i].centerContent[0].header}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'ReplacementCost' && i === '2' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={ReplacementCostData[i].centerContent[0].header}
                                content={ReplacementCostData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}

                {page === 'Clarivu' && i === '0' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                content={ClarivuData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Clarivu' && i === '1' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={ClarivuData[i].centerContent[0].header}
                                content={ClarivuData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Clarivu' && i === '2' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={ClarivuData[i].centerContent[0].header}
                                content={ClarivuData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Clarivu' && i === '3' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={ClarivuData[i].centerContent[0].header}
                                content={ClarivuData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Clarivu' && i === '4' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={ClarivuData[i].centerContent[0].header}
                                content={ClarivuData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}

                {page === 'ReplaceRisks' && i === '0' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                content={ReplaceRisksData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'ReplaceRisks' && i === '1' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={ReplaceRisksData[i].centerContent[0].header}
                                content={ReplaceRisksData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'ReplaceRisks' && i === '2' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={ReplaceRisksData[i].centerContent[0].header}
                                content={ReplaceRisksData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'ReplaceRisks' && i === '3' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={ReplaceRisksData[i].centerContent[0].header}
                                content={ReplaceRisksData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'ReplaceRisks' && i === '4' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={ReplaceRisksData[i].centerContent[0].header}
                                content={ReplaceRisksData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}

                {page === 'Cataracts' && i === '0' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={CataractsData[i].centerContent[0].header}
                                content={CataractsData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Cataracts' && i === '1' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={CataractsData[i].centerContent[0].header}
                                content={CataractsData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Cataracts' && i === '2' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={CataractsData[i].centerContent[0].header}
                                content={CataractsData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Cataracts' && i === '3' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={CataractsData[i].centerContent[0].header}
                                content={CataractsData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Cataracts' && i === '4' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={CataractsData[i].centerContent[0].header}
                                content={CataractsData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Cataracts' && i === '5' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={CataractsData[i].centerContent[0].header}
                                content={CataractsData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Cataracts' && i === '6' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={CataractsData[i].centerContent[0].header}
                                content={CataractsData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Cataracts' && i === '7' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={CataractsData[i].centerContent[0].header}
                                content={CataractsData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}

                {page === 'CataractSurgeryCosts' && i === '0' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={CataractSurgeryCostsData[i].centerContent[0].header}
                                content={CataractSurgeryCostsData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'CataractSurgeryCosts' && i === '1' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={CataractSurgeryCostsData[i].centerContent[0].header}
                                content={CataractSurgeryCostsData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'MedicalTreatments' && i === '0' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={MedicalTreatmentsData[i].centerContent[0].header}
                                content={MedicalTreatmentsData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Glaucoma' && i === '1' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={GlaucomaData[i].centerContent[0].header}
                                content={GlaucomaData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Glaucoma' && i === '2' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={GlaucomaData[i].centerContent[0].header}
                                content={GlaucomaData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Glaucoma' && i === '3' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={GlaucomaData[i].centerContent[0].header}
                                content={GlaucomaData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Glaucoma' && i === '4' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={GlaucomaData[i].centerContent[0].header}
                                content={GlaucomaData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Glaucoma' && i === '5' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={GlaucomaData[i].centerContent[0].header}
                                content={GlaucomaData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Glaucoma' && i === '6' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={GlaucomaData[i].centerContent[0].header}
                                content={GlaucomaData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Floaters' && i === '1' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={FloatersData[i].centerContent[0].header}
                                content={FloatersData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Floaters' && i === '2' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={FloatersData[i].centerContent[0].header}
                                content={FloatersData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Floaters' && i === '3' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={FloatersData[i].centerContent[0].header}
                                content={FloatersData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Hyperopia' && i === '1' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={HyperopiaData[i].centerContent[0].header}
                                content={HyperopiaData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Hyperopia' && i === '2' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={HyperopiaData[i].centerContent[0].header}
                                content={HyperopiaData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Hyperopia' && i === '3' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={HyperopiaData[i].centerContent[0].header}
                                content={HyperopiaData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'RetinalDetachment' && i === '1' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={RetinalDetachmentData[i].centerContent[0].header}
                                content={RetinalDetachmentData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'RetinalDetachment' && i === '2' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={RetinalDetachmentData[i].centerContent[0].header}
                                content={RetinalDetachmentData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'presbyopia' && i === '1' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={PresbiopiaData[i].centerContent[0].header}
                                content={PresbiopiaData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'presbyopia' && i === '2' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={PresbiopiaData[i].centerContent[0].header}
                                content={PresbiopiaData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Astigmatism' && i === '1' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={AstigmatismData[i].centerContent[0].header}
                                content={AstigmatismData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Astigmatism' && i === '2' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={AstigmatismData[i].centerContent[0].header}
                                content={AstigmatismData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Astigmatism' && i === '3' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={AstigmatismData[i].centerContent[0].header}
                                content={AstigmatismData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'DroopyEyelid' && i === '1' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={DroopyEyelidData[i].centerContent[0].header}
                                content={DroopyEyelidData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'DroopyEyelid' && i === '2' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={DroopyEyelidData[i].centerContent[0].header}
                                content={DroopyEyelidData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'DroopyEyelid' && i === '3' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={DroopyEyelidData[i].centerContent[0].header}
                                content={DroopyEyelidData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'DroopyEyelid' && i === '4' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={DroopyEyelidData[i].centerContent[0].header}
                                content={DroopyEyelidData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'MacularHole' && i === '1' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={MacularHoleData[i].centerContent[0].header}
                                content={MacularHoleData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'MacularDegeneration' && i === '1' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={MacularDegenerationData[i].centerContent[0].header}
                                content={MacularDegenerationData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'MacularDegeneration' && i === '2' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={MacularDegenerationData[i].centerContent[0].header}
                                content={MacularDegenerationData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Myopia' && i === '1' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={MyopiaData[i].centerContent[0].header}
                                content={MyopiaData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Myopia' && i === '2' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={MyopiaData[i].centerContent[0].header}
                                content={MyopiaData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Myopia' && i === '3' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={MyopiaData[i].centerContent[0].header}
                                content={MyopiaData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Diabetic' && i === '1' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={DiabeticData[i].centerContent[0].header}
                                content={DiabeticData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
                {page === 'Diabetic' && i === '2' && <div
                    className={`row row-gutter-0 eye-surgery-items-style1 ${surgeryClassOption}`}
                    data-aos="fade-up"
                    data-aos-duration="1100"
                ><div className="row justify-content-center">
                        <div className="col-12 col-sm-10 col-md-8 col-lg-6 -layout">
                            <SectionTitle
                                classOption="text-left"
                                title={DiabeticData[i].centerContent[0].header}
                                content={DiabeticData[i].centerContent[0].content}

                            />
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    );
};

CenterContentContainer.propTypes = {
    page: PropTypes.string,
    i: PropTypes.string,
    surgeryClassOption: PropTypes.string,
};

CenterContentContainer.defaultProps = {
    surgeryClassOption: "row row-gutter-0 eye-surgery-items-style1",
};
export default CenterContentContainer;
