import React from "react";
import Layout from "../layouts/index.jsx";
import Header from "../layouts/header";
import IntroContainer from "../containers/home/intro";
import HealthFeatureContainer from "../containers/home/health-feature";
import MedicalFeatureContainer from "../containers/home/medical-feature";
import AppointmentContainer from "../containers/home/appointment";
import TeamContainer from "../containers/home/team";
// import TestimonialContainer from "../containers/home/testimonial";
// import BrandContainer from "../containers/global/brand";
// import PricingContainer from "../containers/global/pricing";
import EyeSurgeryContainer from "../containers/global/eye-surgery";
import CenterContentContainer from "../containers/global/center-content";
// import LeftContentContainer from "../containers/global/left-content";
import BlogAccordion from "../containers/home/blog-accordion";
import GoogleMapContainer from "../containers/global/map";
import Footer from "../layouts/footer";
import ScrollToTop from "../components/scroll-to-top";
import SEO from "../components/seo";

const HomePage = () => {
    return (
        <React.Fragment>
            <Layout>
                <SEO title="Lerc – Laser Eye &amp; Research Center" />
                <div className="wrapper home-default-wrapper">
                    <Header />
                    <div className="main-content site-wrapper-reveal">
                        <IntroContainer />
                        <HealthFeatureContainer />
                        <EyeSurgeryContainer page="" />
                        <CenterContentContainer page="Home" i="9" />
                        <CenterContentContainer page="Home" i="10" />
                        {/* <LeftContentContainer page="Home" i="11" /> */}
                        <MedicalFeatureContainer />
                        <AppointmentContainer />
                        <TeamContainer />
                        {/* <TestimonialContainer /> */}
                        {/* <BrandContainer />
                        <PricingContainer /> */}
                        <BlogAccordion page="Home" i="0" title="<span>Laser Eye Surgery FAQs</span>" />
                        <GoogleMapContainer />
                    </div>
                    <Footer />
                    <ScrollToTop />
                </div>
            </Layout>
        </React.Fragment>
    );
};

export default HomePage;
